<template>
    <div class="flex flex-col sm:flex-row sm:items-center sm:gap-10 bg-orange-100 lg:bg-gray-50 lg:hover:bg-orange-100 p-20 mb-10 rounded-lg">

        <div class="text-xs font-semibold uppercase mb-10 sm:mb-0 sm:bg-black sm:text-white sm:flex sm:flex-col sm:items-center sm:py-[8px] sm:px-15 sm:rounded">
            <span class="mr-5 sm:mr-0">{{ formatResultDate(courseInstance.start_date, 'D') }}</span>
            <span>{{ formatResultDate(courseInstance.start_date, 'MMM') }}</span>
        </div>

        <div class="sm:flex-1 sm:truncate">
            <a :href="'/course/'+courseInstance.course.slug" class="font-bold" :title="courseInstance.course.title">{{ courseInstance.course.title }}</a>

            <div class="mb-20 md:mb-0">
                <div v-if="courseInstance.dates_string || courseInstance.times_string" class="grid grid-cols-1 md:grid-cols-2 md:gap-20">
                    <p v-if="courseInstance.dates_string" class="text-sm">
                        <font-awesome-icon :icon="['fas', 'calendar']" fixed-width class="mr-10 text-orange"></font-awesome-icon>{{ courseInstance.dates_string }}
                    </p>
                    <p v-if="courseInstance.times_string" class="text-sm">
                        <font-awesome-icon :icon="['fas', 'clock']" fixed-width class="mr-10 text-orange"></font-awesome-icon>{{ courseInstance.times_string }}
                    </p>
                </div>

                <div v-if="courseInstance.venue_location || courseInstance.delivery" class="grid grid-cols-1 md:grid-cols-2 md:gap-20">
                    <p v-if="courseInstance.venue_location" class="lg:mr-20 text-sm">
                        <font-awesome-icon :icon="['fas', 'map-marker']" fixed-width class="mr-10 text-orange"></font-awesome-icon>{{ courseInstance.venue_location }}
                    </p>
                    <p v-if="courseInstance.delivery" :title="courseInstance.delivery" class="text-sm">
                        <font-awesome-icon :icon="['fas', 'info-square']" fixed-width class="mr-10 text-orange"></font-awesome-icon>{{ courseInstance.delivery }}
                    </p>
                </div>
            </div>
        </div>

        <div class="flex items-center justify-between">
            <div class="text-2xl font-semibold sm:w-[140px] sm:text-right sm:mr-10">
                £{{ courseInstance.price_per_attendee }}
            </div>
            <a :href="'/course/'+courseInstance.course.slug+'?instance_id='+courseInstance.id" class="leading-none rounded bg-orange hover:bg-black text-white text-sm text-center px-20 py-[12px]">
                Book Now
            </a>
        </div>
    </div>
</template>

<script setup>

import moment from 'moment'

const props = defineProps({
    courseInstance: {
        type: Object,
    },
    activeMonth: {
        type: String
    }
})

/**
 * Returns a date in the format '24th' for
 * displaying in the results set
 */
 function formatResultDate(date, format) {
    return moment(date).format(format)
}
</script>